'use client'

import { cva } from 'class-variance-authority'
import { ReactNode, forwardRef } from 'react'
import { Dot, IDotColorProps } from '~/core/ui/Dot'
import IconWrapper, { LucideIconName } from '~/core/ui/IconWrapper'
import { cn } from '~/core/ui/utils'
import {
  CheckFill,
  CircleCheckFill,
  CircleCheckLine,
  ClockIcon,
  DotIcon,
  NoFeedbackIcon,
  PendingFeedbackIcon,
  StrongNoFeedbackIcon,
  StrongYesFeedbackIcon,
  XCircleIcon,
  XIcon,
  YesFeedbackIcon
} from './FillIcons'

const badgeNotificationVariant = cva('', {
  variants: {
    color: {
      green: 'bg-green-500 dark:bg-green-800 dark:text-green-100',
      red: 'border border-solid border-white bg-red-500 dark:bg-red-800 dark:text-red-100'
    },
    size: {
      lg: 'h-3.5',
      md: 'h-3.5',
      sm: 'h-3.5',
      xs: 'h-4'
    }
  }
})
const badgeNotificationTextVariant = cva('', {
  variants: {
    size: {
      lg: 'text-sm',
      md: 'text-xs',
      sm: 'text-xs',
      xs: 'text-3xs'
    }
  }
})
const badgeVariants = cva('flex items-center justify-center', {
  variants: {
    color: {
      green:
        'bg-green-100 dark:bg-green-800 text-green-800 dark:text-green-100',
      pastelGreen:
        'bg-pastelGreen-50 dark:bg-pastelGreen-1000 text-pastelGreen-1000 dark:text-pastelGreen-100',
      purple:
        'bg-purple-100 dark:bg-purple-800 text-purple-800 dark:text-purple-100',
      yellow:
        'bg-yellow-100 dark:bg-yellow-800 text-yellow-800 dark:text-yellow-100',
      orange:
        'bg-orange-100 dark:bg-orange-800 text-orange-800 dark:text-orange-100',
      red: 'bg-red-100 dark:bg-red-800 text-red-800 dark:text-red-100',
      blue: 'bg-blue-100 dark:bg-blue-800 text-blue-800 dark:text-blue-100',
      'dark-blue':
        'bg-blue-1000 dark:bg-blue-800 text-blue-1200 dark:text-blue-100',
      gray: 'bg-gray-100 dark:bg-gray-600 text-gray-700 dark:text-gray-100',
      white: 'bg-white dark:bg-gray-600 text-gray-700 dark:text-gray-100',
      'chart-sourced': 'text-blue-800 bg-blue-100',
      'chart-applied': 'text-purple-800 bg-purple-100',
      'chart-screening': 'text-red-800 bg-red-100',
      'chart-client-submission': 'text-orange-800 bg-orange-100',
      'chart-clientSubmission': 'text-orange-800 bg-orange-100',
      'chart-interview': 'text-yellow-800 bg-yellow-100',
      'chart-offer': 'text-pastelGreen-800 bg-pastelGreen-100',
      'chart-hired': 'text-green-800 bg-green-100',
      'chart-onboard': 'text-green-800 bg-green-100',
      'chart-probation': 'text-green-800 bg-green-100',
      'matched-rank-s': 'text-white bg-green-500',
      'matched-rank-a': 'text-white bg-chart-200',
      'matched-rank-b': 'text-white bg-chart-400',
      'matched-rank-c': 'text-white bg-chart-900'
    },
    radius: {
      rounded: 'rounded',
      circular: 'rounded-xl'
    },
    size: {
      sm: 'py-0.5 px-1.5 h-4',
      md: 'py-1 px-1.5 h-5',
      lg: 'py-0.5 px-2 h-6',
      xs: 'py-0.5 px-1 h-4'
    },
    type: {
      default: '',
      dotLeading: '',
      iconLeading: 'flex-row',
      trailingIcon: 'flex-row-reverse',
      notification: ''
    },
    variant: {
      default: '',
      outline:
        'bg-inherit border border-gray-100 dark:border-gray-700 text-gray-700'
    }
  },
  defaultVariants: {
    color: 'gray',
    radius: 'rounded',
    size: 'md',
    type: 'default',
    variant: 'default'
  }
})

const badgeFontVariants = cva('', {
  variants: {
    size: {
      lg: 'text-sm',
      md: 'text-xs',
      sm: 'text-xs',
      xs: 'text-xs'
    },
    variant: {
      default: 'font-medium',
      outline: 'font-normal'
    }
  },
  defaultVariants: {
    size: 'md',
    variant: 'default'
  }
})

const getSizeIconBadges = {
  lg: 14,
  md: 12,
  sm: 12,
  xs: 10
}

type IColorBadgeType =
  | 'green'
  | 'pastelGreen'
  | 'purple'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'blue'
  | 'gray'
  | 'white'
  | 'chart-sourced'
  | 'chart-applied'
  | 'chart-screening'
  | 'chart-client-submission'
  | 'chart-clientSubmission'
  | 'chart-interview'
  | 'chart-offer'
  | 'chart-hired'
  | 'chart-onboard'
  | 'chart-probation'
  | 'matched-rank-s'
  | 'matched-rank-a'
  | 'matched-rank-b'
  | 'matched-rank-c'

interface BadgeProps {
  type?:
    | 'default'
    | 'dotLeading'
    | 'iconLeading'
    | 'trailingIcon'
    | 'notification'
  radius?: 'rounded' | 'circular'
  color?: IColorBadgeType
  dotColor?: IDotColorProps
  size?: 'sm' | 'md' | 'lg' | 'xs'
  badgeCount?: number
  children?: ReactNode
  icon?: LucideIconName
  iconSVG?: LucideIconName | 'CheckCircleLine' | 'QuestionMarkCircle' 
  variant?: 'default' | 'outline'
  className?: string
  classNameText?: string
  classNameIcon?: string
  classNameIconSVG?: string
  startPlus?: number
}

const Badge = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      children,
      type = 'default',
      radius = 'rounded',
      color = 'gray',
      dotColor,
      size = 'md',
      badgeCount: badgeCountNonDefault,
      icon,
      iconSVG,
      variant = 'default',
      className = '',
      classNameText = '',
      classNameIcon = '',
      classNameIconSVG = '',
      startPlus = 10
    },
    ref
  ) => {
    const badgeCount = badgeCountNonDefault || 0
    const renderSVGIcon = () => {
      let icon = undefined

      switch (iconSVG) {
        case 'Ban':
          icon = <StrongNoFeedbackIcon className={classNameIconSVG} />
          break
        case 'ThumbsDown':
          icon = <NoFeedbackIcon className={classNameIconSVG} />
          break
        case 'ThumbsUp':
          icon = <YesFeedbackIcon className={classNameIconSVG} />
          break
        case 'CheckCircle':
          icon = (
            <CircleCheckFill
              className={`!fill-green-500 ${classNameIconSVG}`}
              size={12}
            />
          )
          break
        case 'CheckCircleLine':
          icon = <CircleCheckLine />
          break
        case 'Clock':
          icon = <ClockIcon className={classNameIconSVG} />
          break
        case 'Check':
          icon = <CheckFill className={classNameIconSVG} />
          break
        case 'X':
          icon = <XIcon className={classNameIconSVG} />
          break
        case 'XCircle':
          icon = <XCircleIcon className={classNameIconSVG} />
          break
        case 'Dot':
          icon = <DotIcon className={classNameIconSVG} />
          break
        case 'QuestionMarkCircle':
          icon = <PendingFeedbackIcon/>
          break
        default:
          icon = <StrongYesFeedbackIcon className={classNameIconSVG} />
          break
      }

      return icon
    }

    if (type === 'notification') {
      return (
        <span
          ref={ref}
          className={cn(
            'flex min-w-[17px] items-center justify-center rounded-xl  px-[5px] text-white ',
            badgeNotificationVariant({
              color: color === 'green' ? 'green' : 'red',
              size: size
            })
          )}>
          <span
            className={cn(
              'font-medium',
              badgeNotificationTextVariant({ size })
            )}>
            {badgeCountNonDefault !== undefined
              ? badgeCount > startPlus
                ? `${startPlus}+`
                : badgeCount
              : children}
          </span>
        </span>
      )
    }

    return (
      <span
        ref={ref}
        className={cn(
          badgeVariants({
            color,
            radius,
            size,
            type,
            variant
          }),
          className
        )}>
        {icon && type === 'iconLeading' ? (
          <span
            className={
              children ? (size === 'lg' ? 'mr-[6px]' : 'mr-1') : undefined
            }>
            <IconWrapper
              className={classNameIcon}
              name={icon}
              size={getSizeIconBadges[size]}
            />
          </span>
        ) : null}
        {iconSVG && type === 'iconLeading' ? (
          <span
            className={
              children ? (size === 'lg' ? 'mr-[6px]' : 'mr-1') : undefined
            }>
            {renderSVGIcon()}
          </span>
        ) : null}
        {icon && type === 'trailingIcon' ? (
          <span
            className={
              children ? (size === 'lg' ? 'ml-[6px]' : 'ml-1') : undefined
            }>
            <IconWrapper
              className={classNameIcon}
              name={icon}
              size={getSizeIconBadges[size]}
            />
          </span>
        ) : null}
        {iconSVG && type === 'trailingIcon' ? (
          <span
            className={
              children ? (size === 'lg' ? 'ml-[6px]' : 'ml-1') : undefined
            }>
            {renderSVGIcon()}
          </span>
        ) : null}
        {type === 'dotLeading' ? (
          <Dot
            color={dotColor || color}
            className={cn(
              'flex-none',
              children ? (size === 'lg' ? 'mr-[6px]' : 'mr-1') : undefined
            )}
          />
        ) : null}
        <span
          className={cn(
            badgeFontVariants({ size, variant, className: classNameText })
          )}>
          {children}
        </span>
      </span>
    )
  }
)

Badge.displayName = 'Badge'

export { Badge }
export type { BadgeProps, IColorBadgeType }
